import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import photo from '../img/foto.png'

const navigation = {
  cs: {
    '/': 'Životopis',
    '/publikace': 'Publikace',
    '/clanky': 'Články',
    '/media': 'Media',
    '/kontakt': 'Kontakt',
  },
  en: {
    '/': 'Biography',
    '/publications': 'Publications',
    '/articles': 'Articles',
    '/contact': 'Contact',
  },
  de: {
    '/': 'Biographie',
    '/publikationen': 'Publikationen',
    '/artikel': 'Artikel',
    '/kontakt': 'kontakt',
  },
}

const redirectJs = `
  if (window.location.pathname === '' || window.location.pathname === '/') {
    window.location = '/cs/';
  }
`;


export default ({ data }) => {
  const post = data.markdownRemark
  const currentPath = data.sitePage.path
  const currentLang = currentPath.substr(1, 2)

  return (
    <Layout lang={currentLang}>
      <script type="application/javascript" dangerouslySetInnerHTML={{ __html: redirectJs }} />
      <div id="header" className="jumbotron subhead">
        <div className="container">
          <a href="/">
            <img src={photo} alt="Ing. Jiří Kutáč" />
          </a>
          <a href="/" className="logo" />

          <ul id="lang">
            <li className={`cz ${currentLang === 'cs' && 'active'}`}>
              <Link to="/cs/" activeClassName="active" />
            </li>
            <li className={`en ${currentLang === 'en' && 'active'}`}>
              <Link to="/en/" activeClassName="active" />
            </li>
            <li className={`de ${currentLang === 'de' && 'active'}`}>
              <Link to="/de/" activeClassName="active" />
            </li>
          </ul>

          <div className="navbar">
            <div className="container">
              <ul className="nav">
                {Object.keys(navigation[currentLang]).map(link => (
                  <li key={link}>
                    <Link
                      to={`/${currentLang}${link}`}
                      activeClassName="active"
                    >
                      {navigation[currentLang][link]}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="con-right" />

      <div id="content" className="container">
        <div className="container-fluid">
          <div className="con-top" />
          <div className="row-fluid">
            <div className="span3">
              <h3>Kontakt</h3>

              <p>
                Unie soudn&iacute;ch znalců, z.s.
                <br />
                Pod Vi&scaron;ňovkou 1661/31
                <br />
                140 00 Praha 4 - Krč
                <br />
                <br />
                <strong>Tel:</strong>
                &nbsp;00420 604 250 879
              </p>

              <h3>Odkazy</h3>
              <ul>
                <li>
                  <a href="http://uniesoudnichznalcu.cz">
                    Unie soudn&iacute;ch znalců, z.s.
                  </a>
                  </li>
                 <li>
                  <a href="http://www.dehn.cz">
                    DEHN s.r.o.
                  </a>
                </li>
              </ul>
            </div>
            <div className="span9">
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </div>
        </div>
      </div>

      <div id="footer">
        <div className="container">
          <div className="container-fluid">
            <div className="row-fluid">
              <div className="span4">&nbsp;</div>
              <div className="span4">
                Copyright &copy; www.jirikutac.com 2013 <br />
                Web & design &copy; Pavlína Ostrá 2013 <br />
              </div>
              <div className="span4 pull-right">
                <a href="#">Zpět nahoru</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
    sitePage(path: { eq: $slug }) {
      path
    }
  }
`
